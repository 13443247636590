'use client'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import { BackLink } from '../BackLink'
import messages from './fallback.messages'

const Fallback = () => {
  const formatMessage = useFormatMessage()
  return (
    <div className="flex flex-col">
      <h3 className="font-bold">{formatMessage(messages.title)}</h3>
      <p>
        {formatMessage(messages.textOne)}
        <BackLink>{formatMessage(messages.back)}</BackLink>
        {formatMessage(messages.textTwo)}
      </p>
    </div>
  )
}

export default Fallback

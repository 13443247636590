'use client'

import { cn } from '@/utils/cn'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { PropsWithChildren } from 'react'

export const BackLink = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const router = useRouter()
  return (
    <Link
      href="#"
      className={cn([
        'cursor-pointer underline hover:text-slate-600',
        className,
      ])}
      onClick={() => router.back()}
    >
      {children}
    </Link>
  )
}

import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.pages.authorize'

export default defineMessages({
  title: {
    defaultMessage: 'Something went wrong',
    id: `${messagesPath}.title`,
  },
  textOne: {
    defaultMessage: 'Something went wrong with your login attempt. Please go ',
    id: `${messagesPath}.textOne`,
  },
  back: {
    defaultMessage: 'back',
    id: `${messagesPath}.back`,
  },
  textTwo: {
    defaultMessage: ' and try again',
    id: `${messagesPath}.textTwo`,
  },
})
